<template>
  <div class="groupBuyingList">
    <div class="flexbox flex-lr flex-align-center">
      <el-input v-model="searchForm.groupName" placeholder="请输入团购名称" maxlength="20" style="width:200px;" clearable></el-input>
      <el-input v-model="searchForm.orderId" type="number" placeholder="请输入订单编号" style="width:200px;margin-left:20px;" clearable>
      </el-input>
      <el-select v-model="searchForm.state" class="ml20 mr20" placeholder="状态" style="width:150px;">
        <el-option v-for="item in orderState" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="searchForm.agentSid" placeholder="请选择归属代理商" clearable filterable remote class="mr20" :loading="loading"
        :remote-method="searchAgent" @change="agentSidChange">
        <el-option v-for="item in agentList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-date-picker class="mr20" :clearable="false" v-model="applyTime" type="daterange" range-separator="至" value-format="yyyy-MM-dd"
        start-placeholder="开始时间" end-placeholder="结束时间">
      </el-date-picker>
      <el-button type="info" @click="clear">清空</el-button>
      <el-button type="primary" @click="search">搜索</el-button>
    </div>
    <div class="tableBox">
      <el-table v-loading="loading" :data="ssGroupTable" style="width: 100%" border>
        <el-table-column prop="orderId" label="订单编号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="goodsName" label="团购名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="shopName" label="店铺名称" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="tel" label="客户电话" align="center"></el-table-column>
        <el-table-column prop="agentName" label="所属代理商" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="spreadName" label="所属分公司" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createDate" label="下单时间" align="center">
        </el-table-column>
        <el-table-column prop="" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state===1">未核销</span>
            <span v-if="scope.row.state===2">已核销</span>
            <span v-if="scope.row.state===4">退款成功</span>
            <span v-if="scope.row.state===6">已失效</span>
          </template>
        </el-table-column>
        <el-table-column prop="realPrice" label="订单金额（元）" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.realPrice||'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="抽佣金额（元）" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.commissionPrice||'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="purchasePrice" label="商户收益（元）" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.purchasePrice||'-'}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import { $sg_getSsGroupOrderList, $sp_agentList } from '@/api/order';

export default {
  data() {
    return {
      agentList: [],
      loading: false,
      totalPage: 1,
      currentPage: 1,
      applyTime: ['', ''],
      searchForm: {
        orderId: '',
        groupName: '',
        state: '',
        startTime: '',
        endTime: '',
      },
      orderState: [
        { label: '全部', value: '' },
        { label: '未核销', value: 1 },
        { label: '已核销', value: 2 },
        { label: '退款成功', value: 4 },
        { label: '已失效', value: 6 },
      ],
      ssGroupTable: [
      ],
    };
  },
  created() {
    this.getSsgroupOrderList();
    this.getAgentList();
  },
  methods: {
    // 筛选
    search() {
      this.searchForm.startTime = this.applyTime[0];
      this.searchForm.endTime = this.applyTime[1];
      const params = this.searchForm;
      this.currentPage = 1;
      this.getSsgroupOrderList({
        ...params,
      });
    },
    // 清空
    clear() {
      this.searchForm = {
        agentSid: '',
        orderId: '',
        groupName: '',
        state: '',
        startTime: '',
        endTime: '',
      };
      this.applyTime = ['', ''];
      this.currentPage = 1;
      this.getSsgroupOrderList();
    },
    // 请求列表数据
    getSsgroupOrderList(params) {
      this.loading = true;
      console.log(params);
      // eslint-disable-next-line operator-assignment
      $sg_getSsGroupOrderList({
        currentPage: this.currentPage,
        pageSize: 14,
        ...params,
      }).then((res) => {
        console.log(res.records);
        this.ssGroupTable = res.records;
        this.totalPage = res.pages;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 搜索代理商
    async searchAgent(query) {
      this.loading = true;
      if (query) {
        await this.getAgentList(query);
      } else {
        await this.getAgentList('');
      }
      this.loading = false;
    },
    // 请求代理商列表
    getAgentList(agentName = '') {
      const params = {
        agentName,
      };
      this.agentList = [];
      return $sp_agentList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.agentSid,
            label: item.agentName,
          };
          tempArr.push(json);
        });
        this.agentList = tempArr;
      });
    },
    // 选择代理商
    agentSidChange() {
      this.getAgentList();
      // this.getSsgroupOrderList();
    },
    // 翻页
    pageChange() {
      this.getSsgroupOrderList(this.searchForm);
    },
  },
};
</script>
<style lang="scss" scoped>
.groupBuyingList {
  width: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tips {
    width: 100%;
    margin-top: 40px;
    font-size: 14px;
    color: #666;
  }
  .tableBox {
    width: 100%;
    margin-top: 10px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
  .distri-layout-mark {
    width: 3px;
    display: inline-block;
    height: 19px;
    background: #ef3f46;
    opacity: 1;
  }
  .distri-layout-title {
    padding: 0 10px;
    vertical-align: 4px;
    font-size: 16px;
    color: #4d4d4d;
  }
  .basicInfo {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    p {
      margin-bottom: 5px;
    }
  }
  .otherInfo {
    padding-left: 10px;
    padding-right: 10px;
    > p {
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
    }
    .el-checkbox {
      /deep/.el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }
  .remarks {
    > span {
      width: 50px;
    }
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

